//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import * as d3 from 'd3';
import { mapState, mapActions } from 'vuex';
import constant from '~/const';

export default {
  data() {
    return {
      COLOR: {
        REGISTERED_USERS: '#0242a8',
        BY_REFERRAL_USERS: '#afcdff',
        IDENTIFIED_USERS: '#2e75ee',
      },
      filters: {
        dateFrom: '',
        dateTo: '',
        period: constant.statistics.PERIOD.DAY,
      },
    };
  },

  computed: {
    ...mapState('statisticsUsers', ['chart']),

    data() {
      const { chart } = this;
      return chart.map((e) => ({
        ...e,
        t: new Date(e.t * 1000),
      }));
    },

    yAxisInterval() {
      const { data } = this;
      const arr = [];

      data.forEach((e) => {
        arr.push(e.u);
        arr.push(e.r);
        arr.push(e.rc);
      });

      const min = Math.min.apply(null, arr);
      const max = Math.max.apply(null, arr);

      return [min, max];
    },
  },

  watch: {
    filters: {
      async handler() {
        this.loadChart();
      },
      deep: true,
    },
  },

  mounted() {
    this.loadChart();
  },

  methods: {
    ...mapActions('common', [
      'setGeneralProgress',
    ]),
    ...mapActions('statisticsUsers', {
      loadChartAction: 'loadChart',
      setChartFiltersAction: 'setChartFilters',
    }),

    async loadChart() {
      const { filters } = this;
      await this.setChartFiltersAction({ ...filters });
      this.setGeneralProgress(true);
      await this.loadChartAction();
      this.setGeneralProgress(false);
      this.drawChart();
    },

    drawChart() {
      d3.selectAll('g').remove(); // remove all

      const { data, yAxisInterval, COLOR } = this;
      const { clientWidth, clientHeight } = this.$refs.chart;
      const margin = {
        top: 20,
        right: 20,
        bottom: 30,
        left: 50,
      };
      const width = clientWidth - margin.left - margin.right;
      const height = clientHeight - margin.top - margin.bottom;

      const svg = d3.select('svg');

      const g = svg.append('g')
        .attr('transform', `translate(${margin.left}, ${margin.top})`);

      // set x-axis scale
      const x = d3.scaleTime()
        .rangeRound([0, width]);

      // set y-axis scale
      const y = d3.scaleLinear()
        .rangeRound([height, 0]);

      // create graphs
      const registeredUsersLine = d3.line()
        .x((d) => x(d.t))
        .y((d) => y(d.u));

      const byReferralUsersLine = d3.line()
        .x((d) => x(d.t))
        .y((d) => y(d.r));

      const identifiedUsersLine = d3.line()
        .x((d) => x(d.t))
        .y((d) => y(d.rc));

      // set range
      x.domain(d3.extent(data, (d) => d.t));
      y.domain(yAxisInterval);

      g.append('g')
        .attr('transform', `translate(0, ${height})`)
        .call(d3.axisBottom(x));

      g.append('g')
        .call(d3.axisLeft(y));

      g.append('path')
        .datum(data)
        .attr('fill', 'none')
        .attr('stroke', COLOR.REGISTERED_USERS)
        .attr('stroke-linejoin', 'round')
        .attr('stroke-linecap', 'round')
        .attr('stroke-width', 1.5)
        .attr('d', registeredUsersLine);

      if (!process.env.VUE_APP_EXCLUDE_FEATURE?.split(' ').includes('statistics-users-showByRefProgramGraph')) {
        g.append('path')
          .datum(data)
          .attr('fill', 'none')
          .attr('stroke', COLOR.BY_REFERRAL_USERS)
          .attr('stroke-linejoin', 'round')
          .attr('stroke-linecap', 'round')
          .attr('stroke-width', 1.5)
          .attr('d', byReferralUsersLine);
      }

      g.append('path')
        .datum(data)
        .attr('fill', 'none')
        .attr('stroke', COLOR.IDENTIFIED_USERS)
        .attr('stroke-linejoin', 'round')
        .attr('stroke-linecap', 'round')
        .attr('stroke-width', 1.5)
        .attr('d', identifiedUsersLine);
    },
  },
};
