//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapState } from 'vuex';
import constant from '~/const';

export default {
  data() {
    return {
      constant,
    };
  },

  computed: {
    ...mapState('statisticsUsers', ['data']),

    registered() {
      const { registered } = this.data;
      return registered || {};
    },

    registeredByRef() {
      const { registeredByRef } = this.data;
      return registeredByRef || {};
    },

    verification() {
      const { verification } = this.data;
      return verification || {};
    },

    verificationRequest() {
      const { verificationRequest } = this.data;
      return verificationRequest || {};
    },

    usersWithWarnings() {
      const { usersWithWarnings } = this.data;
      return usersWithWarnings || 0;
    },
  },
};
