//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapActions } from 'vuex';
import StatisticsUsersData from '~/views/StatisticsUsers/StatisticsUsersData/StatisticsUsersData.vue';
import StatisticsUsersGraph from '~/views/StatisticsUsers/StatisticsUsersGraph/StatisticsUsersGraph.vue';
import RegisteredUsers from '~/views/Users/Users.vue';

export default {
  components: {
    StatisticsUsersData,
    StatisticsUsersGraph,
    RegisteredUsers,
  },

  async mounted() {
    this.setGeneralLoader(true);
    await Promise.allSettled([
      this.loadData(),
    ]);
    this.setGeneralLoader(false);
  },

  methods: {
    ...mapActions('common', ['setGeneralLoader']),
    ...mapActions('statisticsUsers', {
      loadData: 'loadData',
    }),
  },
};
